import FarmsService from '@/common/farms.service'
import {
  FARM__ALL_FOR_USER,
  FARM__ALL_WITH_STATS__FOR_USER,
  FARM__FROM_DEVSETID,
  FARM__READ,
  FARM__DELETE,
  FARM__DEACTIVATE,
  FARM__UPDATE,
  FARM__ADD_USER,
  FARM__ADD_MINER,
  FARM__ADD_MINER_NETSCAN,
  FARM__REMOVE_USER,
  FARM__READ_MINERS,
  FARM__READ_MINER_CONFIG,
  FARM__READ_MINER_CONFIG__PRO
} from './actions.type'
import {
  FARM__SET_ALL,
  FARM__SET_ALL_WITH_STATS,
  FARM__SET,
  FARM__ERROR_SET,
  FARM__UNSET,
  FARM__SET_MINERS,
  FARM__SET_MINER_CONFIG,
  FARM__SET_MINER_CONFIG__PRO
} from './mutations.type'
import Farm from '@/models/farm'
import FarmStat from '@/models/farm-stat'
import Miner from '@/models/miner'
import MinerStat from '@/models/miner-stat'
import MinerConfig from '@/models/miner-config'
import MinerConfigPro from '@/models/miner-config-pro'

import { USE_FIXTURES, TELEMETRY_FREQ } from '@/common/config'

const initialState = {
  farms: {
    name: null,
    id: null,
    deviceset_id: null,
    is_active: null,
    owners: []
  }
}

export const state = { ...initialState }

export const getters = {
  farms (state) {
    return state.farms
  },

  farm (state) {
    return state.farm
  }
}

export const actions = {
  async [FARM__ALL_FOR_USER] (context) {
    const { data } = await FarmsService.read()
    context.commit(FARM__SET_ALL, data)
    return data
  },
  async [FARM__ALL_WITH_STATS__FOR_USER] (context) {
    const { data } = await FarmsService.get({ skip: 0, limit: 19, include_stats: true, timespan_seconds: (parseInt(TELEMETRY_FREQ) / 1000) })
    context.commit(FARM__SET_ALL_WITH_STATS, data)
    return data
  },
  async [FARM__READ] (context, farmId) {
    const { data } = await FarmsService.read(farmId)
    context.commit(FARM__SET, data)
    return data
  },
  async [FARM__DELETE] (context, id) {
    const { data } = await FarmsService.delete(id)
    context.commit(FARM__UNSET, data)
    return data
  },
  async [FARM__DEACTIVATE] (context, id) {
    const { data } = await FarmsService.deactivate(id)
    context.commit(FARM__UNSET, data)
    return data
  },
  async [FARM__UPDATE] (context, payload) {
    const { data } = await FarmsService.update(payload.id, payload.params)
    context.commit(FARM__SET, data)
    return data
  },
  async [FARM__ADD_USER] (context, farmId, userEmail) {
    const { data } = await FarmsService.addFarmUser(farmId, userEmail)
    context.commit(FARM__SET, data)
    return data
  },
  async [FARM__ADD_MINER] (context, payload) {
    const { data } = await FarmsService.addMiner(payload.id, payload.params)
    context.commit(FARM__SET, data)
    return data
  },
  async [FARM__ADD_MINER_NETSCAN] (context, payload) {
    const { data } = await FarmsService.addMinerNetScan(payload.id, payload.params)
    context.commit(FARM__UNSET, data) // Buggy. Fix!
    return data
  },
  async [FARM__REMOVE_USER] (context, payload) {
    const { data } = await FarmsService.removeFarmUser(payload.farmId, payload.email)
    context.commit(FARM__SET, data)
    return data
  },
  async [FARM__FROM_DEVSETID] (context, deviceSetId) {
    const { data } = await FarmsService.byDeviceSet(deviceSetId)
    context.commit(FARM__SET_ALL, data)
    return data
  },
  async [FARM__READ_MINERS] (context, params) {
    if (USE_FIXTURES === 'yes') {
      const data = generateFakeMinersForFarm(params.farmId)
      context.commit(FARM__SET_MINERS, { id: params.farmId, miners: data })
      return data
    } else {
      const { data } = await FarmsService.miners(params.farmId, params.skip, params.limit, params.orderBy, params.sortOrder, params.state, params.error_state)
      context.commit(FARM__SET_MINERS, { id: params.farmId, miners: data })
      return data
    }
  },
  async [FARM__READ_MINER_CONFIG] (context, farmId) {
    const { data } = await FarmsService.config(farmId)
    context.commit(FARM__SET_MINER_CONFIG, data)
    return data
  },
  async [FARM__READ_MINER_CONFIG__PRO] (context, farmId) {
    const { data } = await FarmsService.config(farmId)
    context.commit(FARM__SET_MINER_CONFIG__PRO, data)
    return data
  }
}

export const mutations = {
  [FARM__SET_ALL] (state, farms) {
    Farm.create({ data: farms })
  },

  [FARM__SET_ALL_WITH_STATS] (state, farms) {
    const updatedFarms = farms.map((farm) => {
      Farm.insert({ data: { ...farm, id: farm.deviceset_id } })
      if (farm.stats) {
        const stat = { ...farm.stats, farm_id: farm.deviceset_id }
        FarmStat.insert({ data: stat })
      }
      return farm
    })

    state.allFarms = state.allFarms || []

    const farmsToDelete = state.allFarms.filter((farm) => !updatedFarms.some((updatedFarm) => updatedFarm.deviceset_id === farm.deviceset_id))

    farmsToDelete.forEach((farm) => {
      Farm.delete(farm.deviceset_id)
    })

    state.allFarms = updatedFarms
  },

  [FARM__SET] (state, farm) {
    const newFarm = Farm.insert({ data: { ...farm, id: farm.deviceset_id } })
    return newFarm
  },

  [FARM__ERROR_SET] (state, payload) {
    const { farm, err } = payload
    const data = err.response.data
    const invalidFarm = Farm.find(farm.deviceset_id)

    invalidFarm.validate(data, { depth: 2 })
  },

  [FARM__UNSET] (state, farm) {
    Farm.delete(farm.deviceset_id)
    return farm
  },

  [FARM__SET_MINERS] (state, data) {
    data.miners.forEach((miner) => {
      if (miner.latest_stats === null) {
        const minerData = { ...miner, status: 'error', status_msg: 'No stats for miner' }
        Miner.insert({ data: { ...minerData, miner_id: minerData.device_id } })
      } else {
        Miner.insert({ data: { ...miner, miner_id: miner.device_id } })
      }
      const stat = { ...miner.latest_stats, miner_id: miner.device_id }
      MinerStat.insert({ data: stat })
    })
  },

  [FARM__SET_MINER_CONFIG] (state, data) {
    MinerConfig.insert({ data })
  },

  [FARM__SET_MINER_CONFIG__PRO] (state, data) {
    MinerConfigPro.insert({ data })
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

function randomStatus () {
  let status = 'unknown'

  if (Math.random() < 0.6) {
    status = 'online'
  }

  if (Math.random() < 0.2) {
    status = 'offline'
  }

  if (Math.random() < 0.2) {
    status = 'rebooting'
  }

  return status
}

function generateFakeMinersForFarm (farmId) {
  const data = []

  const demoSize = 10
  let demoIndex = 0

  while (demoIndex < demoSize) {
    data.push({ farm_id: farmId, name: `miner_${farmId}`, status: randomStatus() })
    demoIndex++
  }
  return data
}
