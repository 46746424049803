import { Model } from '@vuex-orm/core'
import { MinerConfig } from '../miner-config'

export class HashchainConfig extends Model {
  static entity = 'hashchain_configs'

  static fields () {
    return {
      miner_config: this.belongsTo(MinerConfig, 'minerconfig_id'),
      frequency: this.attr(null),
      voltage: this.attr(null),
      hashchain_number: this.attr(null),
      enabled: this.boolean(false),
      id: this.uid(),
      minerconfig_id: this.number()
    }
  }
}

export default HashchainConfig
