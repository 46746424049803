import MinersService from '@/common/miners.service'
import Miner from '@/models/miner'

import { MINER__UPDATE, MINER__DELETE, MINER__ADD_NOTE, DEVICELIST__LIVE_STATS__TOGGLE, FARM__READ_MINERS } from './actions.type'
import { MINER__SET, MINER__UNSET, MINER__ERROR_SET, DEVICELIST__LIVE_STATS__RECEIVE } from './mutations.type'

import { TELEMETRY_FREQ } from '@/common/config'

const initialState = {
  miners: {},
  receiveDeviceLiveStats: false
}

export const state = {
  ...initialState
}

export const getters = {
  miner (state) {
    return state.miners
  }
}

let intervalId = null

export const actions = {
  async [MINER__UPDATE] (context, payload) {
    await MinersService.update(payload.id, payload.params)
      .then((response) => {
        context.commit(MINER__SET, response)
      }).catch((err) => {
        context.commit(MINER__ERROR_SET, err)
      })
  },
  async [MINER__DELETE] (context, id) {
    const { data } = await MinersService.delete(id)
    context.commit(MINER__UNSET, data)
    return data
  },
  async [MINER__ADD_NOTE] (context, payload) {
    await MinersService.add_note(payload.id, payload.params)
      .then((response) => {
        context.commit(MINER__SET, response)
      }).catch((err) => {
        context.commit(MINER__ERROR_SET, err)
      })
  },
  async [DEVICELIST__LIVE_STATS__TOGGLE] (context, payload) {
    console.log('Telemetry-Frequency: ', TELEMETRY_FREQ)
    await context.commit(DEVICELIST__LIVE_STATS__RECEIVE, payload.status)
    if (payload.status) {
      if (payload.state === 'null' && payload.error_state === 'null') {
        intervalId = setInterval(context.dispatch, parseInt(TELEMETRY_FREQ), FARM__READ_MINERS, { farmId: payload.id, skip: payload.offset, limit: payload.limit, orderBy: payload.orderBy, sortOrder: payload.sortOrder })
      }
      if (payload.state !== 'null' && payload.error_state === 'null') {
        intervalId = setInterval(context.dispatch, parseInt(TELEMETRY_FREQ), FARM__READ_MINERS, { farmId: payload.id, skip: payload.offset, limit: payload.limit, orderBy: payload.orderBy, sortOrder: payload.sortOrder, state: payload.state })
      }
      if (payload.state === 'null' && payload.error_state !== 'null') {
        intervalId = setInterval(context.dispatch, parseInt(TELEMETRY_FREQ), FARM__READ_MINERS, { farmId: payload.id, skip: payload.offset, limit: payload.limit, orderBy: payload.orderBy, sortOrder: payload.sortOrder, error_state: payload.error_state })
      }
      if (payload.state !== 'null' && payload.error_state !== 'null') {
        intervalId = setInterval(context.dispatch, parseInt(TELEMETRY_FREQ), FARM__READ_MINERS, { farmId: payload.id, skip: payload.offset, limit: payload.limit, orderBy: payload.orderBy, sortOrder: payload.sortOrder, state: payload.state, error_state: payload.error_state })
      }
    } else {
      clearInterval(intervalId)
    }
  }
}

export const mutations = {
  [MINER__SET] (state, response) {
    const { data } = response
    Miner.insert({ data: { ...data, miner_id: data.device_id } })
  },
  [MINER__UNSET] (state, response) {
    const { data } = response
    Miner.delete({ data })
  },
  [MINER__ERROR_SET] (state, err) {
    throw err
  },
  [DEVICELIST__LIVE_STATS__RECEIVE] (state, status) {
    state.receiveDeviceLiveStats = status
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
