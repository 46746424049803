<template>
  <div id="app">
    <div v-if='updateAvailable' class='alert alert-info'>
      <translate>There is an updated version of this page available!</translate>
      <br />
      <button type="submit" value="Refresh" class="landingPageButton btn btn-success" style="margin-top: 5px;" @click="forceUpdate()">
        <i class="fas fa-recycle fas-left"></i><span>REFRESH</span>
      </button>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios'
import { AUTH__LOGOUT } from '@/store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['updateAvailable'])
  },
  created: function () {
    var app = this

    axios.interceptors.response.use(undefined, function (err) {
      const response = err.response

      const loggedOutStatus = (response.status === 401) //  || response.status === 403)

      return new Promise((resolve, reject) => {
        if (loggedOutStatus && response.config && !response.config.__isRetryRequest) {
          app.$store.dispatch(AUTH__LOGOUT).then(() => {
            app.$router.push({ name: 'login' })
          })
        }
        throw err
      })
    })
  },
  methods: {
    forceUpdate () {
      window.location.reload()
    }
  }
}
</script>

<style>
  @import './assets/style/base_fg.css';
</style>

<style lang="scss">
  @import './assets/style/forms.scss';
  @import './assets/theme/main.scss';
</style>
