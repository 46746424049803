import FarmStatsService from '@/common/farm-stats.service'
import FarmStat from '@/models/farm-stat'

import { FARM_STATS__READ } from './actions.type'
import { FARM_STATS__SET } from './mutations.type'

import { USE_FIXTURES } from '@/common/config'

const initialState = {
  farm_stats: {}
}

export const state = {
  ...initialState
}

export const getters = {
  farmstats (state) {
    return state.farm_stats
  }
}

export const actions = {
  async [FARM_STATS__READ] (context, payload) {
    const farmId = payload.farmId
    const count = payload.count
    if (USE_FIXTURES === 'yes') {
      const data = buildDemoData(farmId)
      context.commit(FARM_STATS__SET, { farmId, data })
    } else {
      const { data } = await FarmStatsService.fetchStats(farmId, count)
      context.commit(FARM_STATS__SET, { data, farmId })
    }
  }
}

export const mutations = {
  [FARM_STATS__SET] (state, payload) {
    payload.data.forEach((stat) => {
      FarmStat.insert({ data: { ...stat, farm_id: payload.farmId } })
    })
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

function boundedRandom (min, max) {
  return min + Math.random() * (max - min)
}

function demoStatsGenerator (farmId) {
  return {
    farm_id: farmId,
    created: Date.now(),
    hashrate_total: boundedRandom(0, 120),
    fanspeed_avg: boundedRandom(60, 90),
    temperature_avg: boundedRandom(20, 50),
    miner_count_total: boundedRandom(0, 3),
    miner_count_online: boundedRandom(0, 3),
    miner_count_good: boundedRandom(0, 3),
    miner_count_faulty: boundedRandom(0, 3)
  }
}

function buildDemoData (farmId) {
  const demoLimit = 40
  let demoIndex = 0

  const demoData = []

  while (demoIndex < demoLimit) {
    demoData.push(demoStatsGenerator(farmId))
    demoIndex++
  }

  return demoData
}
