import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import dotenv from 'dotenv'

import { ENABLE_GTM } from '@/common/config'
import { AUTH__CHECK, SW__UPDATE_AVAILABLE } from './store/actions.type'
import ApiService from './common/api.service'
import DateFilter from './common/date.filter'
import ErrorFilter from './common/error.filter'

import VueGtm from '@gtm-support/vue2-gtm'
import VueFormulate from '@braid/vue-formulate'
import { en, de } from '@braid/vue-formulate-i18n'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import GetTextPlugin from 'vue-gettext'
// import translations from '../translations/translations.json'
import TranslationService from './common/translation.service'
import 'flag-icon-css/css/flag-icon.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlusSquare, faServer, faSquare,
  faQuestionCircle, faTachometerAlt, faListUl, faCog,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { faUserSecret } from '@fortawesome/pro-regular-svg-icons'

import InfoLabel from '@/components/widgets/forms/InfoLabel'
Vue.component('InfoLabel', InfoLabel)

Vue.use(VueFormulate, {
  slotComponents: {
    label: 'InfoLabel'
  },
  slotProps: {
    label: ['tooltip']
  },
  plugins: [en, de],
  locale: TranslationService.getDefaultLanguage()
})

Vue.component('v-select', vSelect)

Vue.use(GetTextPlugin, {
  availableLanguages: TranslationService.getAvailableLanguages(),
  defaultLanguage: TranslationService.getDefaultLanguage(),
  muteLanguages: TranslationService.getMuteLanguages(),
  languageVmMixin: TranslationService.getLanguageVmMixin(),
  silent: TranslationService.getSilent(),
  translations: TranslationService.getTranslations()
})

library.add(faMinusCircle)
library.add(faCog)
library.add(faListUl)
library.add(faPlusSquare)
library.add(faServer)
library.add(faSquare)
library.add(faQuestionCircle)
library.add(faTachometerAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

dotenv.config()
Vue.config.productionTip = false
Vue.filter('date', DateFilter)
Vue.filter('error', ErrorFilter)

ApiService.init()

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous) {
    Promise.all([store.dispatch(AUTH__CHECK)])
      .then(next)
      .catch((err) => {
        console.log('AUTH FAILED', err)
        next({
          name: 'login',
          query: { redirect: to.fullPath }
        })
      })
  } else {
    next()
  }
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.use(VueGtm, {
  id: 'GTM-KWJZDD7', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  // queryParams: {
  //   // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: (ENABLE_GTM === 'yes'), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const updater = () => {
  app.$store.dispatch(SW__UPDATE_AVAILABLE)
}

window.FG_updated = updater
