
import { Database } from '@vuex-orm/core'
import User from '@/models/user'
import Farm from '@/models/farm'
import NewFarm from '@/models/new-farm'
import FarmPoolConfig from '@/models/farm/pool-config'
import Miner from '@/models/miner'
import MinerConfig from '@/models/miner-config'
import MinerConfigPro from '@/models/miner-config-pro'
import MinerStat from '@/models/miner-stat'
import FarmStat from '@/models/farm-stat'
import PoolConfig from '@/models/miner-config/pool-config'
import PoolGroup from '@/models/miner-config/pool-group'
import GlobalPowerConfig from '@/models/miner-config/global-power'
import HashchainConfig from '@/models/miner-config/hashchain'
import TemperatureConfig from '@/models/miner-config/temperature'
import FanConfig from '@/models/miner-config/fans'
import AutotuneConfig from '@/models/miner-config/autotune'
import PowerScaling from '@/models/miner-config/power-scaling'

const database = new Database()

database.register(User)
database.register(Farm)
database.register(NewFarm)
database.register(FarmPoolConfig)
database.register(Miner)

database.register(MinerConfig)
database.register(MinerConfigPro)
database.register(MinerStat)
database.register(FarmStat)

database.register(PoolConfig)
database.register(PoolGroup)
database.register(GlobalPowerConfig)
database.register(HashchainConfig)
database.register(TemperatureConfig)
database.register(FanConfig)
database.register(AutotuneConfig)
database.register(PowerScaling)

export default database
