import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'
import { API_PROTO, API_FQDN } from '@/common/config'
const qs = require('querystring')

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = `${API_PROTO}://${API_FQDN}`
  },

  setHeader () {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`
  },

  query (resource, params) {
    // the correct way to pass params to get
    return Vue.axios.get(resource, { params })
  },

  // get a singleton resource
  get (resource) {
    return Vue.axios.get(resource).catch(error => {
      throw new Error(`[FARMGOD] ApiService ${error}`)
    })
  },

  // get an identified resource
  read (resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[FARMGOD] ApiService ${error}`)
    })
  },

  putForm (resource, params) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    return Vue.axios.put(`${resource}`, qs.stringify(params), config)
  },

  postForm (resource, params) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    return Vue.axios.post(`${resource}`, qs.stringify(params), config)
  },

  post (resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  patch (resource, params) {
    return Vue.axios.patch(`${resource}`, params)
  },

  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put (resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete (resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[FARMGOD] ApiService ${error}`)
    })
  }
}

export default ApiService
