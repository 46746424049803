import OtpService from '@/common/otp.service'

import {
  OTP__REQUEST_SETUP,
  OTP__COMPLETE_SETUP,
  OTP__REQUEST_CONFIGURED_MODE,
  OTP__CONFIRM
} from './actions.type'
import {
  AUTH__SET_OTP_SECRET,
  AUTH__OTP_SETUP_COMPLETED,
  AUTH__OTP_MODE_SET,
  AUTH__OTP_SETUP_FAILED,
  AUTH__OTP_CONFIRMED
} from './mutations.type'

const initialState = { }
export const state = { ...initialState }
export const getters = { }

export const actions = {
  async [OTP__REQUEST_CONFIGURED_MODE] (context, mode) {
    return new Promise((resolve, reject) => {
      OtpService.otp_configured_modes().then((response) => {
        context.commit(AUTH__OTP_MODE_SET, response)
        resolve(response)
      })
    })
  },
  [OTP__REQUEST_SETUP] (context, mode) {
    return OtpService.otp_setup({ mode }).then((response) => {
      context.commit(AUTH__SET_OTP_SECRET, response)
    })
  },

  async [OTP__COMPLETE_SETUP] (context, finalOtp) {
    return await OtpService.otp_setup({ final_otp: finalOtp }).then((response) => {
      context.commit(AUTH__OTP_SETUP_COMPLETED, response)
    }).catch((err) => {
      context.commit(AUTH__OTP_SETUP_FAILED, err)
      throw err
    })
  },

  async [OTP__CONFIRM] (context, otp) {
    return await OtpService.otp_confirm({ otp }).then((response) => {
      context.commit(AUTH__OTP_CONFIRMED, response)
    })
  }
}

export const mutations = {
}

export default {
  state,
  actions,
  mutations,
  getters
}
