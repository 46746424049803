import {
  SW__UPDATE_AVAILABLE
} from './actions.type'

import { SW__UPDATE_AVAILABLE__SET_ALERT } from './mutations.type'

const initialState = {
  updateAvailable: false
}

const state = {
  ...initialState
}

const getters = {
  updateAvailable (state) {
    return state.updateAvailable
  }
}

export const actions = {
  [SW__UPDATE_AVAILABLE] (context, payload) {
    context.commit(SW__UPDATE_AVAILABLE__SET_ALERT)
  }
}

export const mutations = {
  [SW__UPDATE_AVAILABLE__SET_ALERT] (state, payload) {
    state.updateAvailable = true
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
