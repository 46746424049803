/* eslint-disable no-console */

import { register } from 'register-service-worker'
import alertify from 'alertify.js'

const notifyUserAboutUpdate = worker => {
  alertify.confirm('update available!', () => {
    worker.postMessage({ action: 'skipWaiting' })
  })
}

console.log('Next Level Farm Management _', process.env.NODE_ENV.toUpperCase(), 'env'.toUpperCase())

if (process.env.NODE_ENV === 'production') {
  // console.log('ServiceWorker disabled in registerServiceWorker.js')
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      /* console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      ) */
    },
    registered () {
      // console.log('Service worker has been registered.')
    },
    cached () {
      // console.log('Content has been cached for offline use.')
    },
    updatefound () {
      // console.log('New content is downloading.')
    },
    updated (registration) {
      notifyUserAboutUpdate(registration.waiting)
      // window.FG_updated()
      // console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error:', error)
    }
  })

  var refreshing
  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return
    window.location.reload()
    refreshing = true
  })
}
