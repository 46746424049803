import ApiService from '@/common/api.service'

const ID_OTP_STATUS_KEY = 'otp_status'

export const OtpService = {
  otp_setup (params) {
    return ApiService.query('account/2fa-setup', params)
  },
  otp_confirm (params) {
    return ApiService.query('login/2fa-confirm', params)
  },
  otp_configured_modes () {
    return ApiService.get('account/2fa-configured-modes')
  },
  otp_supported_modes () {
    return ApiService.get('account/2fa-supported-modes')
  },
  saveStatus (otpStatus) {
    window.localStorage.setItem(ID_OTP_STATUS_KEY, otpStatus)
  },
  getStatus () {
    return window.localStorage.getItem(ID_OTP_STATUS_KEY)
  },
  destroyStatus (otpStatus) {
    window.localStorage.removeItem(ID_OTP_STATUS_KEY, otpStatus)
  }
}

export default OtpService
