import { TELEMETRY_FREQ } from '@/common/config'
import ApiService from '@/common/api.service'
const qs = require('querystring')

export const FarmsService = {
  byDeviceSet (deviceSetId) {
    return ApiService.read('farm/query', deviceSetId)
  },
  query (params) {
    return ApiService.query('farm', params)
  },
  withStats () {
    return ApiService.get('farm?skip=0&limit=1&include_stats=true&timespan_seconds=' + (TELEMETRY_FREQ / 1000))
  },
  read (farmId) {
    return ApiService.read('farm', farmId)
  },
  get (params) {
    if (params !== undefined) {
      return ApiService.get(`farm?${qs.stringify(params)}`)
    } else {
      return ApiService.get('farm/')
    }
  },
  create (payload) {
    return ApiService.post('farm', payload)
  },
  update (id, params) {
    return ApiService.update('farm', id, params)
  },
  delete (id) {
    return ApiService.delete(`farm/${id}`)
  },
  deactivate (id) {
    return ApiService.patch(`farm/${id}/deactivate_farm`)
  },
  addFarmUser (id, email) {
    return ApiService.postForm(`farm/${id}/add_user`, { user_email: email })
  },
  addMiner (id, params) {
    return ApiService.post(`farm/${id}/miner?${qs.stringify(params)}`, params)
  },
  addMinerNetScan (id, params) {
    return ApiService.post(`/utils/network-scan/?${qs.stringify(params)}`, params)
  },
  removeFarmUser (id, email) {
    return ApiService.patch(`farm/${id}/remove_user`, { user_email: email })
  },
  miners (farmId, skip, limit, orderBy, sortOrder, state, errorState) {
    if ((state === 'null' && errorState === 'null') || (!state && !errorState)) {
      return ApiService.get(`farm/${farmId}/miner?skip=${skip}&limit=${limit}&sort_by=${orderBy}&sort_order=${sortOrder}&include_stats=false`)
    }
    if ((state !== 'null' && errorState === 'null') || (state !== 'null' && !errorState)) {
      return ApiService.get(`farm/${farmId}/miner?skip=${skip}&limit=${limit}&sort_by=${orderBy}&sort_order=${sortOrder}&state=${state}&include_stats=false`)
    }
    if ((state === 'null' && errorState !== 'null') || (!state && errorState !== 'null')) {
      return ApiService.get(`farm/${farmId}/miner?skip=${skip}&limit=${limit}&sort_by=${orderBy}&sort_order=${sortOrder}&error_state=${errorState}&include_stats=false`)
    }
    if (state !== 'null' && errorState !== 'null') {
      return ApiService.get(`farm/${farmId}/miner?skip=${skip}&limit=${limit}&sort_by=${orderBy}&sort_order=${sortOrder}&state=${state}&error_state=${errorState}&include_stats=false`)
    }
  },
  config (farmId) {
    return ApiService.get(`farm/${farmId}/config`)
  }
}

export default FarmsService
