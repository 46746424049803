import { Model } from '@vuex-orm/core'

export class PowerScaling extends Model {
  static entity = 'powerscalings'

  static fields () {
    return {
      enabled: this.boolean(false),
      power_step: this.attr(null),
      min_psu_power_limit: this.attr(null),
      shutdown_enabled: this.boolean(false),
      shutdown_duration_hours: this.attr(null),
      id: this.uid(),
      minerconfig_id: this.uid()
    }
  }
}

export default PowerScaling
