import MinerStatsService from '@/common/miner-stats.service'
import MinerStat from '@/models/miner-stat'

import { MINER_STATS__READ } from './actions.type'
import { MINER_STATS__SET } from './mutations.type'

const initialState = {
  miner_stats: {}
}

export const state = {
  ...initialState
}

export const getters = {
  minerstats (state) {
    return state.miner_stats
  }
}

export const actions = {
  async [MINER_STATS__READ] (context, minerId) {
    const { data } = await MinerStatsService.fetchStats(minerId, 1)
    context.commit(MINER_STATS__SET, data)
  }
}

export const mutations = {
  [MINER_STATS__SET] (state, data) {
    MinerStat.insert({ data: data })
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
