import {
  LIVE_STAT__CONNECT,
  LIVE_STAT__DISCONNECT,
  LIVE_STAT__RECV_MESSAGE,
  LIVE_STAT__RECV_CONN_OPEN,
  LIVE_STAT__RECV_CONN_CLOSE,
  LIVE_STAT__RECV_ERROR
} from '../actions.type'

import JwtService from '@/common/jwt.service'
import { USE_FIXTURES, API_FQDN, WS_PROTO } from '@/common/config'

let socket = null

export default function createWebSocketPlugin () {
  return store => {
    if (USE_FIXTURES === 'yes') {
      setInterval(demoStatsGenerator, 5000, store)
    } else {
      store.subscribeAction((action) => {
        if (action.type === `livestats/${LIVE_STAT__CONNECT}`) {
          socket = new WebSocket(
            `${WS_PROTO}://${API_FQDN}/websocket/livestats?token=${JwtService.getToken()}&deviceset_id=${action.payload.farmId}`
          )

          socket.onmessage = event => {
            store.dispatch(`livestats/${LIVE_STAT__RECV_MESSAGE}`, JSON.parse(event.data))
          }

          socket.onopen = function (event) {
            store.dispatch(`livestats/${LIVE_STAT__RECV_CONN_OPEN}`)
          }

          socket.onclose = event => {
            store.dispatch(`livestats/${LIVE_STAT__RECV_CONN_CLOSE}`)
          }

          socket.onerror = event => {
            store.dispatch(`livestats/${LIVE_STAT__RECV_ERROR}`, event)
          }
        }

        if (action.type === `livestats/${LIVE_STAT__DISCONNECT}`) {
          if (socket) {
            socket.close()
          }
        }
      })
    }
  }
}

function demoStatsGenerator (store) {
  const data = {
    created: Date.now(),
    hashrate_total: boundedRandom(292162, 12292162),
    fanspeed_avg: boundedRandom(60, 90),
    temperature_avg: boundedRandom(20, 50),
    miner_count_total: boundedRandom(0, 3),
    miner_count_online: boundedRandom(0, 3),
    miner_count_good: boundedRandom(0, 3),
    miner_count_faulty: boundedRandom(0, 3)
  }
  store.dispatch(`livestats/${LIVE_STAT__RECV_MESSAGE}`, data)
}

function boundedRandom (min, max) {
  return min + Math.random() * (max - min)
}
