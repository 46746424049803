import { Model } from '@vuex-orm/core'
import { PoolGroup } from './miner-config/pool-group-pro'

export class MinerConfig extends Model {
  static entity = 'pro_miner_configs'

  static fields () {
    return {
      id: this.uid(),
      deviceset_id: this.uid(),
      updated_at: this.number(),
      custom_fanspeed: this.boolean(),
      fans_pct: this.attr(),
      pool_groups: this.hasMany(PoolGroup, 'minerconfig_id')
    }
  }
}

export default MinerConfig
