import { Model } from '@vuex-orm/core'
import { NewFarm } from '@/models/new-farm'

export class FarmPoolConfig extends Model {
  static entity = 'pool_configs'

  static fields () {
    return {
      id: this.uid(),
      farm: this.belongsTo(NewFarm, 'farm_id'),
      farm_id: this.uid(),
      url: this.string(),
      username: this.string(),
      password: this.string(),
      api_key: this.attr(),
      api: this.attr(),
      enabled: this.boolean(true)
    }
  }
}

export default FarmPoolConfig
