export default function getEnv (name) {
  return window?.configs?.[name] || process.env[name]
}

export const USE_FIXTURES = getEnv('VUE_APP_USE_FIXTURES')
export const TELEMETRY_FREQ = getEnv('VUE_APP_TELEMETRY_FREQ')
export const METRICS_FQDN = getEnv('VUE_APP_METRICS_FQDN')
export const METRICS_PROTO = getEnv('VUE_APP_METRICS_PROTO')
export const API_FQDN = getEnv('VUE_APP_API_FQDN')
export const API_PROTO = getEnv('VUE_APP_API_PROTO')
export const WS_PROTO = getEnv('VUE_APP_WS_PROTO')
export const ENABLE_GTM = getEnv('VUE_APP_ENABLE_GTM')
export const THEME = getEnv('VUE_APP_THEME')
export const PRODUCT_MODE = getEnv('VUE_APP_PRODUCT_MODE')
export const TRANSLATION_AVAIL_LANGS = getEnv('VUE_APP_TRANSLATION_AVAIL_LANGS')
export const TRANSLATION_SRC_LANG = getEnv('VUE_APP_TRANSLATION_SRC_LANG')
export const REGISTRATION_ENABLED = getEnv('VUE_APP_REGISTRATION_ENABLED')
export const TWO_FACTOR_AUTH_REQUIRED = getEnv('VUE_APP_TWO_FACTOR_AUTH_REQUIRED')
