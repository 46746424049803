import { Model } from '@vuex-orm/core'

export class FarmStat extends Model {
  static entity = 'farm_stats';

  static fields () {
    return {
      farm_id: this.attr(null),
      created: this.number(),
      hashrate_total: this.number(),
      hashrate_unit: this.string(),
      pool_hashrate_5m: this.number(),
      pool_hashrate_1h: this.number(),
      pool_hashrate_12h: this.number(),
      pool_hashrate_24h: this.number(),
      temperature_avg: this.number(),
      fanspeed_avg: this.number(),
      miner_count_total: this.number(),
      miner_count_online: this.number(),
      miner_count_good: this.number(),
      miner_count_faulty: this.number(),
      power_consumption: this.attr(''),
      pool_status: this.attr('')
    }
  }

  get hashrateTotalAsTH () {
    return this.hashrate_total
  }

  get hashratePoolAsTH () {
    return this.pool_hashrate_12h
  }

  get temperatureAvg () {
    return parseFloat(this.temperature_avg)
  }

  get fanspeedAvg () {
    if (parseFloat(this.fanspeed_avg) > 100) {
      return 100
    } else {
      return parseFloat(this.fanspeed_avg)
    }
  }

  static lastInRangeForFarm (farmId, size) {
    const mostRecent = this.query().where('farm_id', farmId).orderBy('created', 'desc').get()
    return mostRecent.slice(0, size).reverse()
  }

  static lastForFarm (farmId) {
    return this.query().where('farm_id', farmId).last()
  }
}

export default FarmStat
