import { Model } from '@vuex-orm/core'
import { MinerConfig } from '../miner-config'

export class GlobalPowerConfig extends Model {
  static entity = 'global_power_configs';

  static fields () {
    return {
      enable_asic_boost: this.boolean(true),
      frequency: this.attr(null),
      voltage: this.attr(null),
      id: this.uid(),
      minerconfig_id: this.uid(),
      miner_config: this.belongsTo(MinerConfig, 'minerconfig_id')
    }
  }
}

export default GlobalPowerConfig
