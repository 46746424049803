export const SW__UPDATE_AVAILABLE = 'SW__UPDATE_AVAILABLE'

export const AUTH__REQUEST_ACCESS_TOKEN = 'AUTH__REQUEST_ACCESS_TOKEN'
export const AUTH__REGISTER = 'AUTH__REGISTER'
export const AUTH__LOGOUT = 'AUTH__LOGOUT'
export const AUTH__CHECK = 'AUTH__CHECK'
export const AUTH__FORGOT_PASSWORD = 'AUTH__FORGOT_PASSWORD'
export const AUTH__RESET_PASSWORD = 'AUTH__RESET_PASSWORD'
export const AUTH__VERIFY_ACCOUNT = 'AUTH__VERIFY_ACCOUNT'

export const AUTH__UPDATE_USER = 'AUTH__UPDATE_USER'

export const OTP__METHOD = 'OTP__METHOD'
export const OTP__REQUEST_SETUP = 'OTP__REQUEST_SETUP'
export const OTP__REQUEST_CONFIGURED_MODE = 'OTP__REQUEST_CONFIGURED_MODE'
export const OTP__COMPLETE_SETUP = 'OTP__COMPLETE_SETUP'
export const OTP__CONFIRM = 'OTP__CONFIRM'
// export const OTP__REQUEST_EMAIL = 'OTP__REQUEST_EMAIL'

// export const FETCH_USERS_FARMS = 'fetchUsersFarms'
// export const QUERY_FOR_FARM = 'queryForFarm'
// export const ADD_FARM_USER = 'addFarmUser'
// export const READ_FARM = 'readFarm'

export const NEW_FARM__SPAWN = 'NEW_FARM__SPAWN'
export const FARM__CREATE = 'FARM__CREATE'
export const FARM__CREATE_PROGRESS = 'FARM__CREATE_PROGRESS'

export const FARM__ALL_FOR_USER = 'FARM__ALL_FOR_USER'
export const FARM__ALL_WITH_STATS__FOR_USER = 'FARM__ALL_WITH_STATS__FOR_USER'
export const FARM__FROM_DEVSETID = 'FARM__FROM_DEVSETID'
export const FARM__ADD_USER = 'FARM__ADD_USER'
export const FARM__REMOVE_USER = 'FARM__REMOVE_USER'
export const FARM__READ = 'FARM__READ'
export const FARM__DELETE = 'FARM__DELETE'
export const FARM__DEACTIVATE = 'FARM__DEACTIVATE'
export const FARM__UPDATE = 'FARM__UPDATE'
export const FARM__ADD_MINER = 'FARM__ADD_MINER'
export const FARM__ADD_MINER_NETSCAN = 'FARM__ADD_MINER_NETSCAN'

export const FARM__READ_MINERS = 'FARM__READ_MINERS'
export const FARM__READ_MINER_CONFIG = 'FARM__READ_MINER_CONFIG'
export const FARM__READ_MINER_CONFIG__PRO = 'FARM__READ_MINER_CONFIG__PRO'

export const FARM_STATS__READ = 'FARM_STATS__READ'

export const MINER__ADD_NOTE = 'MINER__ADD_NOTE'
export const MINER__UPDATE = 'MINER__UPDATE'
export const MINER__DELETE = 'MINER__DELETE'

export const MINER_CONFIG__UPDATE = 'MINER_CONFIG__UPDATE'
export const MINER_CONFIG__UPDATE__PRO = 'MINER_CONFIG__UPDATE__PRO'

export const MINER_CONFIG__UPDATE_FORCED = 'MINER_CONFIG__UPDATE_FORCED'

export const LIVE_STAT__CONNECT = 'LIVE_STAT__CONNECT'
export const LIVE_STAT__DISCONNECT = 'LIVE_STAT__DISCONNECT'
export const LIVE_STAT__RECV_MESSAGE = 'LIVE_STAT__RECV_MESSAGE'
export const LIVE_STAT__DELETE_MESSAGE = 'LIVE_STAT__DELETE_MESSAGE'
export const LIVE_STAT__RECV_CONN_OPEN = 'LIVE_STAT__RECV_CONN_OPEN'
export const LIVE_STAT__RECV_CONN_CLOSE = 'LIVE_STAT__RECV_CONN_CLOSE'
export const LIVE_STAT__RECV_ERROR = 'LIVE_STAT__RECV_ERROR'

export const MINER_STATS__READ = 'MINER_STATS__READ'

export const OVERVIEW__LIVE_STATS__TOGGLE = 'OVERVIEW__LIVE_STATS__TOGGLE'
export const DEVICELIST__LIVE_STATS__TOGGLE = 'DEVICELIST__LIVE_STATS__TOGGLE'

export const USER__UPDATE = 'USER__UPDATE'
