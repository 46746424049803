import { Model } from '@vuex-orm/core'
import { GlobalPowerConfig } from './miner-config/global-power'
import { HashchainConfig } from './miner-config/hashchain'
import { TemperatureConfig } from './miner-config/temperature'
import { FanConfig } from './miner-config/fans'
import { AutotuneConfig } from './miner-config/autotune'
import { PowerScaling } from './miner-config/power-scaling'
import { PoolGroup } from './miner-config/pool-group'

export class MinerConfig extends Model {
  static entity = 'miner_configs'

  static fields () {
    return {
      id: this.uid(),
      deviceset_id: this.uid(),
      updated_at: this.number(),
      telemetry_submit_period: this.number(120),
      disable_autoupgrade: this.boolean(true),
      pool_groups: this.hasMany(PoolGroup, 'minerconfig_id'),
      global_power_config: this.hasOne(GlobalPowerConfig, 'minerconfig_id'),
      hash_chain_configs: this.hasMany(HashchainConfig, 'minerconfig_id'),
      temperature_config: this.hasOne(TemperatureConfig, 'minerconfig_id'),
      fan_config: this.hasOne(FanConfig, 'minerconfig_id'),
      autotune_config: this.hasOne(AutotuneConfig, 'minerconfig_id'),
      power_scaling: this.hasOne(PowerScaling, 'minerconfig_id')
    }
  }

  static afterCreate (model) {
    if (!AutotuneConfig.query().where('minerconfig_id', model.id).first()) {
      AutotuneConfig.create({
        data: {
          minerconfig_id: model.id
        }
      })
    }

    if (!GlobalPowerConfig.query().where('minerconfig_id', model.id).first()) {
      GlobalPowerConfig.create({
        data: {
          minerconfig_id: model.id
        }
      })
    }

    if (!PowerScaling.query().where('minerconfig_id', model.id).first()) {
      PowerScaling.create({
        data: {
          minerconfig_id: model.id
        }
      })
    }

    if (!TemperatureConfig.query().where('minerconfig_id', model.id).first()) {
      TemperatureConfig.create({
        data: {
          minerconfig_id: model.id
        }
      })
    }

    if (!FanConfig.query().where('minerconfig_id', model.id).first()) {
      FanConfig.create({
        data: {
          minerconfig_id: model.id
        }
      })
    }
  }
}

export default MinerConfig
