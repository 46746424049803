import { Model } from '@vuex-orm/core'

export class FanConfig extends Model {
  static entity = 'fan_configs'

  static fields () {
    return {
      speed: this.attr(null),
      min_fans: this.attr(null),
      id: this.uid(),
      minerconfig_id: this.uid()
    }
  }
}

export default FanConfig
