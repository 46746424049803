import { Model } from '@vuex-orm/core'
import { MinerConfig } from '../miner-config'

export class TemperatureConfig extends Model {
  static entity = 'temperature_configs'

  static fields () {
    return {
      mode: this.attr(null),
      target_temperature: this.attr(null),
      hot_temperature: this.attr(null),
      dangerous_temperature: this.attr(null),
      id: this.uid(),
      minerconfig_id: this.uid(),
      miner_config: this.belongsTo(MinerConfig, 'minerconfig_id')
    }
  }
}

export default TemperatureConfig
