import { Model } from '@vuex-orm/core'
import { MinerConfig } from '../miner-config'

export class AutotuneConfig extends Model {
  static entity = 'autotune_configs'

  static fields () {
    return {
      enabled: this.boolean(true),
      psu_power_limit: this.attr(null),
      id: this.uid(),
      minerconfig_id: this.uid(),
      miner_config: this.belongsTo(MinerConfig, 'minerconfig_id')
    }
  }
}

export default AutotuneConfig
