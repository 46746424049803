import Vue from 'vue'
import ApiService from '@/common/api.service'
import JwtService from '@/common/jwt.service'
import OtpService from '@/common/otp.service'
import {
  AUTH__REQUEST_ACCESS_TOKEN,
  AUTH__LOGOUT,
  AUTH__REGISTER,
  AUTH__CHECK,
  AUTH__UPDATE_USER,
  AUTH__FORGOT_PASSWORD,
  AUTH__RESET_PASSWORD,
  AUTH__VERIFY_ACCOUNT
} from './actions.type'
import {
  AUTH__SET_OTP_SECRET,
  AUTH__OTP_SETUP_COMPLETED,
  AUTH__OTP_SETUP_FAILED,
  AUTH__OTP_MODE_SET,
  AUTH__OTP_CONFIRMED,
  AUTH__SET_USER,
  AUTH__SET,
  AUTH__PURGE,
  AUTH__SET_ERROR,
  AUTH__RESET_ERROR
} from './mutations.type'

const state = {
  authenticationErrors: {},
  user: {},
  isAuthenticated: () => { return JwtService.isAuthenticated() },
  twofaStatus: null,
  twofaToken: null,
  otpSecret: null,
  otpConfiguredMode: null
}

const getters = {
  authenticationErrors (state) {
    return state.authenticationErrors
  },
  hasAuthErrors (state) {
    return (Object.keys(state.authenticationErrors).length > 0)
  },
  currentUser (state) {
    return state.user
  },
  isAuthenticated (state) {
    return () => {
      return JwtService.isAuthenticated()
    }
  },
  twofaStatus (state) {
    // possible values
    //
    //
    // "2FA_DISABLED_GLOBALLY"
    // "2FA_EXPIRED_TOKEN"
    // "2FA_INVALID_STATE"
    // "2FA_OTP_REQUIRED",
    // "2FA_SETUP_PENDING",
    // "2FA_SETUP_PENDING"
    // "2FA_INVALID_MODE"
    // "2FA_INVALID_OTP"
    // "2FA_GENERAL_ERROR"
    // "2FA_SETUP_DONE"
    // "2FA_INVALID_MODE"
    // tfa_setup_headers = {"x-2fa-status": "2FA_SETUP_STARTED"}
    // "2FA_SETUP_FAILED"
    // "2FA_SETUP_NOT_INITALIZED"
    //
    return state.twofaStatus
  },
  twofaToken (state) {
    return state.twofaToken
  },
  otpSecret (state) {
    return state.otpSecret
  },
  otpConfiguredMode () {
    return state.otpConfiguredMode
  },
  authTimeout () {
    return state.authTimeout
  }
}

const actions = {
  async [AUTH__REQUEST_ACCESS_TOKEN] (context, credentials) {
    return await ApiService.postForm('login/access-token', credentials)
      .then(data => context.commit(AUTH__SET, data))
  },
  async [AUTH__REGISTER] (context, credentials) {
    return await ApiService.post('account/register', credentials)
  },
  async [AUTH__FORGOT_PASSWORD] (context, credentials) {
    return await ApiService.post('account/forgot-password', credentials)
  },
  async [AUTH__RESET_PASSWORD] (context, credentials) {
    const token = credentials.token
    delete credentials.token

    JwtService.saveToken(token)
    ApiService.setHeader()
    return await ApiService.post('account/reset-password?', credentials)
  },
  async [AUTH__VERIFY_ACCOUNT] (context, credentials) {
    const token = credentials.token
    delete credentials.token

    JwtService.saveToken(token)
    ApiService.setHeader()
    await ApiService.post('account/verify-account', credentials)
      .then(({ data }) => {
        context.commit(AUTH__SET_USER, data)
      })
  },
  [AUTH__LOGOUT] (context) {
    context.commit(AUTH__PURGE)
  },
  async [AUTH__CHECK] (context) {
    if (JwtService.getToken()) {
      ApiService.setHeader()
      await ApiService.get('users/me')
        .then(({ data }) => {
          context.commit(AUTH__SET_USER, data)
        })
        .catch((err) => {
          context.commit(AUTH__SET_ERROR, err)
          throw err
        })
    } else {
      context.commit(AUTH__PURGE)
      return Promise.reject(new Error('auth purged'))
    }
  },
  [AUTH__UPDATE_USER] (context, payload) {
    const { email, username, password, image, bio } = payload
    const user = {
      email,
      username,
      bio,
      image
    }
    if (password) {
      user.password = password
    }

    return ApiService.put('users/me', user).then(({ data }) => {
      context.commit(AUTH__SET_USER, data.user)
      return data
    })
  }
}

const mutations = {
  [AUTH__SET_ERROR] (state, err) {
    if (parseInt(err.response.status) === 400) {
      state.errors = [err.response.data]
      Vue.set(state, 'authenticationErrors', { root: [err.response.data] })
    } else if (parseInt(err.response.status) === 422) {
      const errorsObject = { email: [], username: [], password: [] }
      err.response.data.detail
        .map((err) => {
          errorsObject[err.loc[1]].push(err.msg)
        })
      Vue.set(state, 'authenticationErrors', errorsObject)
    }
  },
  [AUTH__RESET_ERROR] (state, err) {
    Vue.set(state, 'authenticationErrors', {})
  },
  [AUTH__SET_USER] (state, user) {
    Vue.set(state, 'user', user)
  },
  [AUTH__SET] (state, auth) {
    if (auth.status === 200) {
      state.isAuthenticated = true
      state.authTimeout = false
      state.errors = {}

      JwtService.saveToken(auth.data.access_token)
      ApiService.setHeader()
    } else if (auth.status === 202) {
      // after standard login "2FA_OTP_REQUIRED",
      // after login on registration "2FA_SETUP_PENDING",
      const twofaStatus = auth.headers['x-2fa-status']
      const twofaToken = auth.headers['x-2fa-token']

      JwtService.saveToken(twofaToken)
      ApiService.setHeader()
      OtpService.saveStatus(twofaStatus)
      Vue.set(state, 'twofaStatus', twofaStatus)
      Vue.set(state, 'twofaToken', twofaToken)
      state.errors = {}
    }
  },
  [AUTH__PURGE] (state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    state.authTimeout = true
    JwtService.destroyToken()
  },

  [AUTH__SET_OTP_SECRET] (state, response) {
    const otpSecret = response.headers['x-2fa-secret']
    const otpStatus = response.headers['x-2fa-status']

    OtpService.saveStatus(otpStatus)

    Vue.set(state, 'otpSecret', otpSecret)
    Vue.set(state, 'twofaStatus', otpStatus)
  },

  [AUTH__OTP_SETUP_COMPLETED] (state, response) {
    const otpStatus = response.headers['x-2fa-status']

    OtpService.saveStatus(otpStatus)
    Vue.set(state, 'twofaStatus', otpStatus)

    // vv IMPORTANT vv
    Vue.set(state, 'otpSecret', null)
  },

  [AUTH__OTP_SETUP_FAILED] (state, err) {
    if (err.response) {
      const otpStatus = err.response.headers['x-2fa-status']
      OtpService.saveStatus(otpStatus)
      Vue.set(state, 'twofaStatus', otpStatus)
    } else if (err.request) {
      console.log(err.request)
    } else {
      console.log('Error', err.message)
      console.log('Error stat', err.status)
    }
  },

  [AUTH__OTP_MODE_SET] (state, response) {
    Vue.set(state, 'otpConfiguredMode', response.data[0])
  },

  [AUTH__OTP_CONFIRMED] (state, response) {
    state.isAuthenticated = true
    state.errors = {}
    JwtService.saveToken(response.data.access_token)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
