export const SW__UPDATE_AVAILABLE__SET_ALERT = 'SW__UPDATE_AVAILABLE__SET_ALERT'

export const AUTH__PURGE = 'AUTH__PURGE'
export const AUTH__SET = 'AUTH__SET'
export const AUTH__SET_USER = 'AUTH__SET_USER'
export const AUTH__SET_ERROR = 'AUTH__SET_ERROR'
export const AUTH__RESET_ERROR = 'AUTH__RESET_ERROR'
export const AUTH__RESET_STATE = 'AUTH__RESET_STATE'

export const AUTH__SET_OTP_SECRET = 'AUTH__SET_OTP_SECRET'
export const AUTH__UNSET_OTP_SECRET = 'AUTH__UNSET_OTP_SECRET'

export const AUTH__OTP_SETUP_COMPLETED = 'AUTH__OTP_SETUP_COMPLETED'
export const AUTH__OTP_SETUP_FAILED = 'AUTH__OTP_SETUP_FAILED'
export const AUTH__OTP_MODE_SET = 'AUTH__OTP_MODE_SET'
export const AUTH__OTP_MODE_SET_ERROR = 'AUTH__OTP_MODE_SET_ERROR'
export const AUTH__OTP_CONFIRMED = 'AUTH__OTP_CONFIRMED'

export const OTP__ERROR = 'OTP__ERROR'

export const FARM__SET_ALL = 'FARM__SET_ALL'
export const FARM__SET_ALL_WITH_STATS = 'FARM__SET_ALL_WITH_STATS'
export const FARM__SET = 'FARM__SET'
export const FARM__ERROR_SET = 'FARM__ERROR_SET'
export const FARM__UNSET = 'FARM__UNSET'
export const FARM__SET_MINERS = 'FARM__SET_MINERS'
export const FARM__SET_MINER_CONFIG = 'FARM__SET_MINER_CONFIG'
export const FARM__SET_MINER_CONFIG__PRO = 'FARM__SET_MINER_CONFIG__PRO'

export const FARM_STATS__SET = 'FARM_STATS__SET'

export const NEW_FARM__PROGRESS = 'NEW_FARM__PROGRESS'
export const NEW_FARM__SET = 'NEW_FARM__SET'
export const NEW_FARM__RESET = 'NEW_FARM__RESET'
export const NEW_FARM__INIT = 'NEW_FARM__INIT'
export const NEW_FARM__ERROR_SET = 'NEW_FARM__ERROR_SET'

export const MINER_CONFIG__SET = 'MINER_CONFIG__SET'
export const MINER_CONFIG__SET__PRO = 'MINER_CONFIG__SET__PRO'
export const MINER_CONFIG_ERROR__SET = 'MINER_CONFIG_ERROR__SET'
export const MINER_CONFIG_ERROR__SET__PRO = 'MINER_CONFIG_ERROR__SET__PRO'
export const MINER_CONFIG__UPDATE = 'MINER_CONFIG__UPDATE'

export const LIVE_STAT__CONNECTED = 'LIVE_STAT__CONNECTED'
export const LIVE_STAT__ADD_MESSAGE = 'LIVE_STAT__ADD_MESSAGE'
export const LIVE_STAT__REMOVE_MESSAGE = 'LIVE_STAT__REMOVE_MESSAGE'
export const LIVE_STAT__SET_CONN_STATE = 'LIVE_STAT__SET_CONN_STATE'
export const LIVE_STAT__SET_ERROR = 'LIVE_STAT__SET_ERROR'

export const MINER_STATS__SET = 'MINER_STATS__SET'

export const MINER__SET = 'MINER__SET'
export const MINER__UNSET = 'MINER__UNSET'
export const MINER__ERROR_SET = 'MINER__ERROR_SET'

export const OVERVIEW__LIVE_STATS__RECEIVE = 'OVERVIEW__LIVE_STATS__RECEIVE'
export const DEVICELIST__LIVE_STATS__RECEIVE = 'DEVICELIST__LIVE_STATS__RECEIVE'

export const USER__SET = 'USER__SET'
