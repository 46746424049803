import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'base',
      component: () => {
        return import('@/views/Overview')
      }
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/Login'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'register',
      path: '/register',
      component: () => import('@/views/Register'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'verify_account',
      path: '/verify_account',
      component: () => import('@/views/VerifyAccount'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'forgot_password',
      path: '/forgot_password',
      component: () => import('@/views/ForgotPassword'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'reset_password',
      path: '/reset_password',
      component: () => import('@/views/ResetPassword'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: '2fa_setup__choose_method',
      path: '/2fa_setup/choose_method',
      component: () => import('@/views/2faSetup/Selector'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: '2fa_setup__otp_app',
      path: '/2fa_setup/otp_app',
      component: () => import('@/views/2faSetup/OtpApp'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: '2fa_setup__otp_email',
      path: '/2fa_setup/otp_email',
      component: () => import('@/views/2faSetup/OtpEmail'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'otp__app',
      path: '/otp/app',
      component: () => import('@/views/Otp/App'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'otp__email',
      path: '/otp/email',
      component: () => import('@/views/Otp/Email'),
      meta: {
        allowAnonymous: true
      }
    },
    {
      name: 'overview',
      path: '/overview',
      component: () => {
        return import('@/views/Overview')
      },
      meta: {
        allowAnonymous: false
      }
    },
    {
      name: 'detail',
      path: '/farm/:farm_id/detail',
      component: () => {
        return import('@/views/Detail')
      },
      children: []
    },
    {
      name: 'detail_devices',
      path: '/farm/:farm_id/detail/devices',
      component: () => {
        return import('@/views/Detail/Devices')
      },
      children: []
    },
    {
      name: 'detail_minerconfig_bos',
      path: '/farm/:farm_id/detail/config_bos',
      component: () => {
        return import('@/views/Detail/BosConfig')
      },
      children: []
    },
    {
      name: 'detail_minerconfig_pro',
      path: '/farm/:farm_id/detail/config_pro',
      component: () => {
        return import('@/views/Detail/ProConfig')
      },
      children: []
    }
  ]
})
