import translations from '../../translations/translations.json'
import { TRANSLATION_AVAIL_LANGS, TRANSLATION_SRC_LANG } from '@/common/config'

const ID_TRANSLATION_PREFER_LANG_KEY = 'translation_prefer_lang'

const translationsLangs = Object.keys(translations)

const parseAvailLangs = () => {
  try {
    return JSON.parse(TRANSLATION_AVAIL_LANGS)
  } catch {
    return {}
  }
}

const verifyAvailLangs = (obj) => Object.keys(obj).reduce((a, e) => {
  if (e === TRANSLATION_SRC_LANG || translationsLangs.includes(e)) a[e] = obj[e]
  return a
}, {})

const ensureAvailSrcLang = (obj) => {
  if (TRANSLATION_SRC_LANG && !obj[TRANSLATION_SRC_LANG]) {
    return { ...obj, [TRANSLATION_SRC_LANG]: TRANSLATION_SRC_LANG }
  } else {
    return obj
  }
}

const availLangs = ensureAvailSrcLang(verifyAvailLangs(parseAvailLangs()))

export const TranslationService = {
  getAvailableLanguages () {
    // Object, e.g.
    // {
    //     en_GB: 'British English',
    //     en_US: 'American English',
    //     en: 'English',
    //     de: 'Deutsch',
    //     es_US: 'Español',
    //     fr_FR: 'Français',
    //     it_IT: 'Italiano',
    // }
    return availLangs
  },
  getDefaultLanguage () {
    // string, e.g.
    // 'en' or 'fr_FR'
    const preferLang = window.localStorage.getItem(ID_TRANSLATION_PREFER_LANG_KEY)
    if (preferLang && availLangs[preferLang]) {
      return preferLang
    } else {
      const locale = navigator.language || navigator.userLanguage
      if (locale && availLangs[locale]) {
        return locale
      } else {
        if (locale && availLangs[locale.split('-')[0]]) {
          return locale.split('-')[0]
        } else {
          return TRANSLATION_SRC_LANG
        }
      }
    }
  },
  saveDefaultLanguage (lang) {
    window.localStorage.setItem(ID_TRANSLATION_PREFER_LANG_KEY, lang)
  },
  getMuteLanguages () {
    // Array of strings, e.g.
    // ['en', 'de']
    return []
  },
  getLanguageVmMixin () {
    // Object, e.g.
    // {
    //     computed: {
    //         currentKebabCase: function () {
    //             return this.current.toLowerCase().replace('_', '-')
    //         },
    //     },
    // }
    return {}
  },
  getSilent () {
    // bool
    return true
  },
  getTranslations () {
    return translations
  }
}

export default TranslationService
