import ApiService from '@/common/api.service'

export const UserService = {
  get () {
    return ApiService.get('users')
  },
  update (userId, params) {
    return ApiService.update('users', userId, params)
  }
}

export default UserService
