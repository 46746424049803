import { Model } from '@vuex-orm/core'
import { PoolGroup } from './pool-group-pro'

export class PoolConfig extends Model {
  static entity = 'farm_pool_configs'

  static fields () {
    return {
      pool_group: this.belongsTo(PoolGroup, 'poolgroup_id'),
      poolgroup_id: this.uid(),
      id: this.uid(),
      url: this.string(),
      username: this.string(),
      password: this.string(),
      api_key: this.attr(),
      api: this.attr(),
      enabled: this.boolean(false)
    }
  }
}

export default PoolConfig
