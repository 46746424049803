import Vue from 'vue'
import Vuex from 'vuex'

import serviceworker from './serviceworker.module'
import auth from './auth.module'
import otp from './otp.module'
import farms from './farms.module'
import newfarms from './newfarms.module'
import livestats from './livestats.module'
import minerconfigs from './minerconfigs.module'
import minerstats from './minerstats.module'
import miners from './miners.module'
import farmstats from './farmstats.module'
import user from './user.module'
import liveStatSocket from './plugins/livestat.socket'
import VuexORM from '@vuex-orm/core'
import database from '@/database'

import overview from './overview.module'

import Validations from 'vorm-validations'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    serviceworker,
    auth,
    otp,
    overview,
    livestats,
    farms,
    newfarms,
    miners,
    minerconfigs,
    farmstats,
    minerstats,
    user
  },
  plugins: [VuexORM.install(database), liveStatSocket()]
})

VuexORM.use(Validations)
export default store
