import { Model } from '@vuex-orm/core'
import Miner from '@/models/miner'

export class MinerStat extends Model {
  static entity = 'miner_stats';

  static fields () {
    return {
      id: this.uid(),
      miner_id: this.attr(null),
      created: this.number(),
      hw_errors: this.number(),
      hashrate_ghs_1m: this.number(),
      hashrate_ghs_5m: this.number(),
      pool_hashrate_5m: this.number(),
      pool_hashrate_1h: this.number(),
      pool_hashrate_12h: this.number(),
      pool_hashrate_24h: this.number(),
      temperatures: this.attr([]),
      fanspeeds: this.attr([]),
      active_boards: this.number(),
      active_fans: this.number(),
      active_asic_chips: this.attr([]),
      shares_accepted: this.number(),
      shares_rejected: this.number(),
      shares_stale: this.number(),
      pool_shares_accepted: this.number(),
      pool_shares_rejected: this.number(),
      pool_shares_stale: this.number(),
      pool_shares_invalid: this.number(),
      power_consumption: this.attr(''),
      error_state: this.attr(''),
      miner: this.belongsTo(Miner, 'miner_id')
    }
  }

  get formatActiveBoards () {
    return (this.active_boards || 0)
  }

  get format5SecHashrate () {
    return ((this.hashrate_ghs_5s || 0) / 1000).toFixed(2)
  }

  get format1MinHashrate () {
    return ((this.hashrate_ghs_1m || 0) / 1000).toFixed(2)
  }

  get format5MinHashrate () {
    return ((this.hashrate_ghs_5m || 0) / 1000).toFixed(2)
  }

  get formatHWE () {
    return (this.hw_errors || 0).toFixed(0)
  }

  get activeChipAvg () {
    return this.average(this.active_asic_chips).toFixed(0)
  }

  get activeChipSum () {
    return this.sum(this.active_asic_chips).toFixed(0)
  }

  get tempsAvg () {
    return this.average(this.temperatures).toFixed(0)
  }

  get fansAvg () {
    if ((this.sum(this.fanspeeds) / (10500 / 100)) > 100) {
      return 100
    } else {
      return (this.sum(this.fanspeeds) / (10500 / 100)).toFixed(0)
    }
  }

  average (array) {
    const filtered = array.filter(item => item !== 0)
    if (filtered && filtered.length > 0) {
      return filtered.reduce((a, b) => a + b) / filtered.length
    } else {
      return 0
    }
  }

  sum (array) {
    if (array && array.length > 0) {
      return array.reduce((a, b) => a + b)
    } else {
      return 0
    }
  }
}

export default MinerStat
