import { OVERVIEW__LIVE_STATS__TOGGLE, FARM__ALL_WITH_STATS__FOR_USER } from './actions.type'

import { OVERVIEW__LIVE_STATS__RECEIVE } from './mutations.type'

import { TELEMETRY_FREQ } from '@/common/config'

const state = {
  receiveLiveStats: false
}

const getters = {}

let intervalId = null

const actions = {
  [OVERVIEW__LIVE_STATS__TOGGLE] (context, status) {
    context.commit(OVERVIEW__LIVE_STATS__RECEIVE, status)
    if (status) {
      intervalId = setInterval(context.dispatch, parseInt(TELEMETRY_FREQ), FARM__ALL_WITH_STATS__FOR_USER)
    } else {
      clearInterval(intervalId)
    }
  }
}

const mutations = {
  [OVERVIEW__LIVE_STATS__RECEIVE] (state, status) {
    state.receiveLiveStats = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
