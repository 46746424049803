import MinerConfigsService from '@/common/miner-configs.service'
import MinerConfig from '@/models/miner-config'
import MinerConfigPro from '@/models/miner-config-pro'

import {
  MINER_CONFIG__UPDATE, MINER_CONFIG__UPDATE__PRO
} from './actions.type'
import {
  MINER_CONFIG__SET, MINER_CONFIG_ERROR__SET,
  MINER_CONFIG__SET__PRO, MINER_CONFIG_ERROR__SET__PRO
} from './mutations.type'

const initialState = {
  miner_config: {
  }
}

export const state = {
  ...initialState
}

export const getters = {
  miner_configs (state) {
    return state.miner_configs
  }
}

export const actions = {
  [MINER_CONFIG__UPDATE] (context, payload) {
    return MinerConfigsService.update(payload.configId, payload.configData).then((data) => {
      context.commit(MINER_CONFIG__SET, data)
    }).catch((err) => {
      if (err.response) {
        context.commit(MINER_CONFIG__SET, payload.configData)
        context.commit(MINER_CONFIG_ERROR__SET, { id: payload.configId, err })
        throw err
      }
    })
  },
  [MINER_CONFIG__UPDATE__PRO] (context, payload) {
    return MinerConfigsService.update(payload.configId, payload.configData).then((data) => {
      context.commit(MINER_CONFIG__SET__PRO, data)
    }).catch((err) => {
      if (err.response) {
        context.commit(MINER_CONFIG__SET__PRO, payload.configData)
        context.commit(MINER_CONFIG_ERROR__SET__PRO, { id: payload.configId, err })
        throw err
      }
    })
  }
}

export const mutations = {
  [MINER_CONFIG__SET] (state, data) {
    MinerConfig.create({ data: data })
  },

  [MINER_CONFIG_ERROR__SET] (state, payload) {
    const { id, err } = payload
    const data = err.response.data
    const minerConfig = MinerConfig.find(id)

    minerConfig.validate(data, { depth: 1 })
  },
  [MINER_CONFIG__SET__PRO] (state, data) {
    MinerConfigPro.create({ data: data })
  },

  [MINER_CONFIG_ERROR__SET__PRO] (state, payload) {
    const { id, err } = payload
    const data = err.response.data
    const minerConfig = MinerConfigPro.find(id)

    minerConfig.validate(data, { depth: 1 })
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
