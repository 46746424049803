import { Model } from '@vuex-orm/core'

export class User extends Model {
  static entity = 'users'

  static fields () {
    return {
      id: this.attr(null),
      farm_id: this.attr(null),
      is_active: this.boolean(true),
      is_superuser: this.boolean(false),
      email: this.attr(''),
      curtailment_enabled: this.boolean(false)
    }
  }
}

export default User
