const jwt = require('jsonwebtoken')
const ID_TOKEN_KEY = 'id_token'

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export const exp = () => {
  const tokenString = getToken()
  const token = jwt.decode(tokenString)
  const exp = new Date(token.exp * 1000)
  return exp
}

export const sessionNotExpired = () => {
  const now = parseInt(Date.now())
  return (now < exp())
}

export const isAuthenticated = () => {
  return (!!getToken() && sessionNotExpired())
}

export default { getToken, saveToken, destroyToken, exp, sessionNotExpired, isAuthenticated }
