import { Model } from '@vuex-orm/core'
import Farm from '@/models/farm'
import MinerStat from '@/models/miner-stat'

export class Miner extends Model {
  static entity = 'miners';

  static fields () {
    return {
      id: this.uid(),
      farm_id: this.attr(null),
      model_id: this.attr(''),
      name: this.attr(''),
      worker_name: this.attr(''),
      device_id: this.uid(),
      ip_address: this.attr(''),
      mac_address: this.attr(''),
      mining_software_signature: this.attr(''),
      os_version: this.attr(''),
      platform_name: this.attr(''),
      stats_timestamp: this.attr(''),
      active_asic_chips: this.attr(''),
      active_asic_chips_array: this.attr(''),
      hashrate_ghs_1m: this.attr(''),
      hashrate_ghs_5m: this.attr(''),
      pool_hashrate_5m: this.number(),
      pool_hashrate_1h: this.number(),
      pool_hashrate_12h: this.number(),
      pool_hashrate_24h: this.number(),
      active_fans: this.attr(''),
      active_boards: this.attr(''),
      shares_accepted: this.attr(''),
      shares_rejected: this.attr(''),
      shares_stale: this.attr(''),
      shares_invalid: this.attr(''),
      pool_shares_accepted: this.number(),
      pool_shares_rejected: this.number(),
      pool_shares_stale: this.number(),
      pool_shares_invalid: this.number(),
      temperatures: this.attr(''),
      temperatures_array: this.attr(''),
      fanspeeds: this.attr(''),
      fanspeeds_array: this.attr(''),
      hw_errors: this.attr(''),
      pool_status: this.attr(''),
      status: this.attr(''),
      status_msg: this.string(''),
      total_secs_measurement: this.number(),
      autotuning_running: this.number(),
      note: this.attr(),
      power_consumption: this.attr(''),
      state: this.number(),
      error_state: this.number(),
      farm: this.belongsTo(Farm, 'farm_id')
      // stats: this.hasMany(MinerStat, 'miner_id')
    }
  }

  static allForFarm (farmId, orderBy, sortOrder) {
    // return Miner.query().where({ farm_id: farmId, sort_by: orderBy, sort_order: sortOrder }).orderBy(orderBy, sortOrder)
    return Miner.query().where({ farm_id: farmId, sort_by: orderBy, sort_order: sortOrder }).orderBy(orderBy, sortOrder)
  }

  statsVector () {
    return MinerStat.query().where('miner_id', this.device_id).orderBy('created')
  }

  get latestStats () {
    // returns a single MinerStat
    return this.statsVector().last()
  }

  get formatActiveBoards () {
    return (this.active_boards || 0)
  }

  get format1MinHashrate () {
    return ((this.hashrate_ghs_1m || 0) / 1000).toFixed(2)
  }

  get formatHWE () {
    return (this.hw_errors || 0).toFixed(0)
  }

  get activeChipSum () {
    return this.sum(this.active_asic_chips).toFixed(0)
  }

  get tempsAvg () {
    return this.average(this.temperatures).toFixed(0)
  }

  get fansAvg () {
    if ((this.sum(this.fanspeeds) / (10500 / 100)) > 100) {
      return 100
    } else {
      return (this.sum(this.fanspeeds) / (10500 / 100)).toFixed(0)
    }
  }

  average (array) {
    const filtered = array.filter(item => item !== 0)
    if (filtered && filtered.length > 0) {
      return filtered.reduce((a, b) => a + b) / filtered.length
    } else {
      return 0
    }
  }

  sum (array) {
    if (array && array.length > 0) {
      return array.reduce((a, b) => a + b)
    } else {
      return 0
    }
  }
}

export default Miner
