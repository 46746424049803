import ApiService from '@/common/api.service'

export const MinerConfigsService = {
  get () {
    return ApiService.get('minerconfig')
  },
  update (configId, params) {
    return ApiService.update('minerconfig', configId + '?force_update=true', params)
  }
}

export default MinerConfigsService
