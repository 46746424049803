import { Model } from '@vuex-orm/core'
import { PoolConfig } from './pool-config'
import { MinerConfig } from '../miner-config'

export class PoolGroup extends Model {
  static entity = 'pool_groups'

  static fields () {
    return {
      id: this.uid(),
      cache_timestamp: this.attr(Date.now()),
      name: this.string('Untitled Group'),
      quota: this.attr(1),
      work_distribution_mode: this.string('quota'),
      fixed_share_ratio: this.attr(null),
      pool_configs: this.hasMany(PoolConfig, 'poolgroup_id'),
      minerconfig_id: this.uid(),
      miner_config: this.belongsTo(MinerConfig, 'minerconfig_id')
    }
  }

  static afterCreate (model) {
    if (!PoolConfig.query().where('poolgroup_id', model.id).first()) {
      PoolConfig.insert({
        data: {
          poolgroup_id: model.id
        }
      })
    }
  }
}

export default PoolGroup
