import { Model } from '@vuex-orm/core'
import { User } from './user'
import { Miner } from './miner'
import { MinerConfig } from './miner-config'
import { FarmPoolConfig } from './farm/pool-config'
import { FarmStat } from './farm-stat'

export class Farm extends Model {
  static entity = 'farms'

  static fields () {
    return {
      id: this.uid(),
      name: this.attr(),
      pool_config: this.hasOne(FarmPoolConfig, 'farm_id'),
      deviceset_id: this.string('Generating Farm-ID... Please wait!'),
      is_active: this.boolean(true),
      remote_support_enabled: this.boolean(false),
      owners: this.hasMany(User, 'farm_id'),
      miners: this.hasMany(Miner, 'farm_id'),
      config: this.hasOne(MinerConfig, 'farm_id'),
      price_kwh: this.attr(''),
      currency: this.attr(''),
      worker_mode: this.attr(''),
      visible: this.boolean(true),
      stats: this.hasMany(FarmStat, 'farm_id')
    }
  }
}

export default Farm
