<template>
  <label class='info-label' :for="context.id">
    {{ context.label }}

    <font-awesome-icon :icon="['fas', 'question-circle']" :title='tooltip' />
  </label>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    },
    tooltip: {
      type: [String],
      default: ''
    }
  }
}
</script>

<style>
/* styles? sure... */
</style>
