import UserService from '@/common/user.service'
import { USER__UPDATE } from './actions.type'
import { USER__SET } from './mutations.type'

const initialState = {
  currentUser: null
}

export const state = {
  ...initialState
}

export const getters = {
  currentUser (state) {
    return state.currentUser
  }
}

export const actions = {
  async [USER__UPDATE] (context, payload) {
    await UserService.update(payload.id, payload.params)
      .then((response) => {
        context.commit(USER__SET, response.data)
      })
      .catch((err) => {
        context.commit('USER__ERROR_SET', err)
        throw err
      })
  }
}

export const mutations = {
  [USER__SET] (state, user) {
    state.currentUser = user
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
