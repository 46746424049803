import ApiService from '@/common/api.service'

export const MinersService = {
  get () {
    return ApiService.get('miner')
  },
  update (minerId, params) {
    return ApiService.update('miner', minerId, params)
  },
  delete (minerId) {
    return ApiService.delete(`miner/${minerId}`)
  },
  add_note (minerId, params) {
    return ApiService.patch(`miner/${minerId}/add_note`, params)
  }
}

export default MinersService
