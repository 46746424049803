import Vue from 'vue'
import FarmsService from '@/common/farms.service'
import {
  NEW_FARM__SPAWN,
  FARM__CREATE,
  FARM__CREATE_PROGRESS
} from './actions.type'
import {
  NEW_FARM__SET,
  // NEW_FARM__RESET,
  NEW_FARM__INIT,
  NEW_FARM__PROGRESS,
  NEW_FARM__ERROR_SET
} from './mutations.type'
import NewFarm from '@/models/new-farm'
import FarmPoolConfig from '@/models/farm/pool-config'

const spawnNewFarm = () => {
  const newFarm = new NewFarm()
  newFarm.errors = {}
  newFarm.isNew = true
  newFarm.pool_config = new FarmPoolConfig()
  newFarm.pool_config.errors = {}
  newFarm.pool_config.farm_id = newFarm.id

  return newFarm
}

// spawn new farm on app load so the component
// is always initialized with a newfarm
//
const newFarm = spawnNewFarm()

const initialState = {
  newFarm,
  createFarmWizard: 'closed'
}

export const state = { ...initialState }

export const getters = {
  createFarmWizard (state) {
    return state.createFarmWizard
  },

  newFarm (state) {
    return state.newFarm
  }
}

export const actions = {
  async [NEW_FARM__SPAWN] (context) {
    const newFarm = new NewFarm()
    newFarm.errors = {}
    newFarm.isNew = true
    newFarm.visible = false
    // context.commit(NEW_FARM__RESET)
    await NewFarm.deleteAll()

    return NewFarm.insert({
      data: {
        ...newFarm,
        pool_config: new FarmPoolConfig()
      }
    }).then((entities) => {
      context.commit(NEW_FARM__INIT)
    })
  },
  [FARM__CREATE] (context, farm) {
    const poolConfig = (({ url, username, password, enabled }) => ({ url, username, password, enabled }))(farm.pool_config)
    const params = {
      name: farm.name,
      worker_mode: farm.workerMode,
      pool_config: poolConfig
    }

    return FarmsService.create(params).then((data) => {
      data = { ...data.data, pool_config: state.newFarm.pool_config }

      NewFarm.insert({ data: data }).then((entities) => {
        context.commit(NEW_FARM__SET, entities)
      })
    }).catch((err) => {
      const data = err.response.data

      state.newFarm.validate(data, { depth: 1, rootKey: 'name' }).then((res) => {
        context.commit(NEW_FARM__ERROR_SET, { farm, err })
      })
      // throw err
    })
  },
  [FARM__CREATE_PROGRESS] (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(NEW_FARM__PROGRESS, payload)
      resolve()
    })
  }
}

export const mutations = {
  // [NEW_FARM__RESET] (state, data) {
  //   Vue.set(state, 'newFarm', spawnNewFarm())
  // },

  [NEW_FARM__SET] (state, data) {
    const id = data.new_farms[0].id
    const newFarm = NewFarm.query().where({ id: id }).with('pool_config').get()[0]
    Vue.set(state, 'newFarm', newFarm)
  },

  [NEW_FARM__INIT] (state) {
    const newFarm = NewFarm.query().with('pool_config').last()

    Vue.set(state, 'newFarm', newFarm)
  },

  [NEW_FARM__PROGRESS] (state, progress) {
    Vue.set(state, 'createFarmWizard', progress)
  },

  [NEW_FARM__ERROR_SET] (state, payload) {
    const { farm } = payload
    // extract the farm again plus the errors from the validation
    const invalidFarm = NewFarm.query().where({ id: state.newFarm.id }).with('pool_config').last()
    invalidFarm.name = farm.name
    farm.pool_config.errors = invalidFarm.pool_config.errors
    invalidFarm.pool_config = farm.pool_config

    Vue.set(state, 'newFarm', invalidFarm)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
