import FarmStat from '@/models/farm-stat'

import {
  LIVE_STAT__CONNECT,
  LIVE_STAT__DISCONNECT,
  LIVE_STAT__RECV_MESSAGE,
  LIVE_STAT__RECV_CONN_OPEN,
  LIVE_STAT__RECV_CONN_CLOSE,
  LIVE_STAT__RECV_ERROR
} from './actions.type'
import {
  LIVE_STAT__CONNECTED,
  LIVE_STAT__ADD_MESSAGE,
  LIVE_STAT__SET_CONN_STATE,
  LIVE_STAT__SET_ERROR
} from './mutations.type'

import { TELEMETRY_FREQ } from '@/common/config'

const state = {
  farmId: null,
  latestStatMessage: {},
  liveStatErrorMessage: null,
  limit: parseInt(TELEMETRY_FREQ) / 1000
}

const getters = {
  latestStatMessage: state => state.latestStatMessage,
  liveStatErrorMessage: state => state.liveStatErrorMessage
}

const actions = {
  [LIVE_STAT__CONNECT] ({ commit }, payload) {
    commit(LIVE_STAT__CONNECTED, payload.farmId)
  },
  [LIVE_STAT__DISCONNECT] ({ commit }) {
    commit(LIVE_STAT__CONNECTED, null)
  },
  [LIVE_STAT__RECV_MESSAGE] ({ commit }, message) {
    commit(LIVE_STAT__ADD_MESSAGE, message)
  },
  [LIVE_STAT__RECV_CONN_OPEN] ({ commit }) {
    commit(LIVE_STAT__SET_CONN_STATE, true)
  },
  [LIVE_STAT__RECV_CONN_CLOSE] ({ commit }) {
    commit(LIVE_STAT__SET_CONN_STATE, false)
  },
  [LIVE_STAT__RECV_ERROR] ({ commit }, error) {
    commit(LIVE_STAT__SET_ERROR, error)
  }
}

const mutations = {
  [LIVE_STAT__ADD_MESSAGE] (state, message) {
    if (message.msg_type === 'error') {
      state.liveStatErrorMessage = message
    } else {
      state.liveStatErrorMessage = null
      state.latestStatMessage = message

      FarmStat.insert({ data: { ...message, farm_id: state.farmId } })
    }
  },
  [LIVE_STAT__SET_CONN_STATE] (state, status) {
    state.connected = status
  },
  [LIVE_STAT__SET_ERROR] (state, error) {
    state.error = error
  },

  [LIVE_STAT__CONNECTED] (state, farmId) {
    state.farmId = farmId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
